<template>
  <a-modal v-model="visible" title="固件升级" ok-text="确认" cancel-text="取消" @ok="onOk">
    <a-descriptions title="" bordered size="small" :column="{sm:1}" style="margin-bottom: 20px">
      <a-descriptions-item label="产品">{{device.productName}}</a-descriptions-item>
      <a-descriptions-item label="MAC">{{device.name}}</a-descriptions-item>
      <a-descriptions-item label="WIFI固件">{{device.firmwareVersion === null ? '暂无版本信息' : device.firmwareVersion}} </a-descriptions-item>
      <a-descriptions-item label="MCU固件">{{device.mcuVersion === null ? '暂无版本信息' : device.mcuVersion}} </a-descriptions-item>
    </a-descriptions>

    <a-radio-group v-model="type" style="margin-bottom: 10px">
      <a-radio-button value="0">WIFI固件</a-radio-button>
      <a-radio-button value="1">MCU固件</a-radio-button>
    </a-radio-group>

    <a-select v-model="firmwareId" style="width: 100%">
      <a-select-option value="">请选择要升级的固件</a-select-option>
      <a-select-option v-for="item in firmwareList" :value="item.id"> {{item.name}} （ {{item.version}} ）</a-select-option>
    </a-select>

  </a-modal>
</template>

<script>
  export default {
    data(){
      return {
        visible:false,
        device:{},
        firmwareId:"",
        type:"0",

        wifiFirmwareList:[],
        mcuFirmwareList:[]
      }
    },
    computed:{
      firmwareList(){
        return this.type=="0"?this.wifiFirmwareList:this.mcuFirmwareList
      }
    },
    methods:{
      show(device){
        this.device = JSON.parse(JSON.stringify(device))
        this.visible=true
        this.getFirmwareList()
      },
      onOk(){
        if(this.firmwareId>0){
          this.$post("firmware/upgrade",{
            iotId:this.device.iotId,
            firmwareId:this.firmwareId
          }).then(suc=>{
            this.visible=false
            this.$message.success('已发送固件升级指令');
          })
        }
      },
      getFirmwareList(){
        let paras = {
          pageNum: 1,
          pageSize: 5,
          sortField: "createdAt",
          sortOrder: "descend",
          productKey: this.device.productKey,
          type:0
        }
        this.$get("firmware", paras).then((r) => {
          this.wifiFirmwareList = r.data.rows
        })

        paras.type=1
        this.$get("firmware", paras).then((r) => {
          this.mcuFirmwareList = r.data.rows
        })
      }
    }
  }
</script>

<style scoped>

</style>
